import { IndustrySetResponseDto } from 'store/apiResponse';

import { ProductEnum, ProductStatusEnum, ProductsEnum } from 'constants/CompanyHomeEnum';

import { Sic } from './Industry';
import { Workflow } from './Workflow';

// Autobahn Company
export interface AutobahnCompany {
  id: string;
  tradeName: string;
  legalName: string;
  primaryName: string;
  secondaryName: string;
  countryCode: string;
  location: string;
  shortLocation: string;
  latitude: number;
  longitude: number;
  foundingDate: string;
  legalEntity: string;
  autobahnPhones?: { number: string; type: string }[];
  employeeCount: string;
  taxId?: string;
  slug: string;
  dunsSuffix: string;
  autobahnEmails?: { address: string }[];
}

// Dunsguide Company
export interface DunsguideCompanyPersonTranslations {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  title: string;
  description: string;
  language_iso_code: string;
  slug: string;
}

export interface DunsguideCompanyPerson {
  id: string;
  order: number;
  image: string;
  translations: DunsguideCompanyPersonTranslations[];
  slug: string;
  name: string;
  title: string;
  description: Record<string, string>;
}

export interface DunsguideCompanyGallery {
  id: string;
  image: string;
  video: string;
  title: string;
  type: string;
  order: number;
}

export interface DunsguideCompanyPlan {
  type: string;
}

export interface DunsguideCompanyLanguage {
  isoCode: string;
}

export interface DunsguideCompanyLanguagesService {
  language: DunsguideCompanyLanguage;
}

export interface DunsguideCompanyTranslation {
  description: string;
}

export enum SealTypeEnum {
  ESG_REGISTERED = 'esg_registered',
  DUNS_REGISTERED = 'duns_registered',
  DUNS_CREDIBILITY = 'duns_credibility',
  DUNS_CREDIBILITY_PREMIUM = 'duns_credibility_premium',
  DUNS_100 = 'duns_100',
}

export interface DunsguideCompanySeal {
  type: SealTypeEnum;
  startDate: string;
  endDate: string;
}

export interface DunsguideCompanyMeta {
  contactByEmailEnabled: boolean;
}

export interface DunsLocationsServed {
  id: number;
  description: Record<string, string>;
  fullName: Record<string, string>;
  slug: Record<string, string>;
}

export interface DunsguideCompanyPhone {
  number: string;
  type: string;
  order: number;
}

export interface DunsguideCompanyEmail {
  email: string;
  type: string;
  order: number;
}

export interface DunsguideCompanyOpeningTime {
  day: string;
  openingTime?: string;
  closingTime?: string;
}

export interface DunsguideCompanyInfographics {
  createdAt: string;
  updatedAt: string;
  id: string;
  value: number;
  type: string;
  valueName: string;
  currency: string;
  year: number;
  companyId: string;
}

export interface DunsguideCompany {
  phones: DunsguideCompanyPhone[];
  emails: DunsguideCompanyEmail[];
  openingTime: DunsguideCompanyOpeningTime[];
  infographics: DunsguideCompanyInfographics[];
  people: DunsguideCompanyPerson[];
  gallery: DunsguideCompanyGallery[];
  plans: DunsguideCompanyPlan[];
  languagesService: DunsguideCompanyLanguagesService[];
  createdAt: string;
  updatedAt: string;
  id: string;
  logo: string;
  background: string;
  facebook: string;
  tiktok: string;
  instagram: string;
  linkedin: string;
  twitter: string;
  youtube: string;
  googleplus: string;
  website: string;
  legalEntity: string;
  isHidden: boolean;
  hideEmail: boolean;
  hidePhone: boolean;
  useTradeNameAsPrimary: boolean;
  translations: DunsguideCompanyTranslation[];
  badges: DunsguideCompanySeal[];
  description: Record<string, string>;
  meta: DunsguideCompanyMeta;
  locationsServed: DunsLocationsServed[];
}

export enum CompanyStatusEnum {
  PENDING = 0,
  ACTIVE = 1,
}

export enum ClaimRequestStatus {
  CREATED = 1,
  DOCUMENTS_HAVE_BEEN_UPLOADED = 2,
  ON_REVIEW = 3,
  DECLINED = 4,
  APPROVED = 5,
}

export enum BundleProcessStatus {
  NO_BUNDLE = 'NO_BUNDLE',
  PROCESSING = 'PROCESSING',
  INFORMATION_REQUIRED = 'INFORMATION_REQUIRED',
  PRODUCTS_DELIVERED = 'PRODUCTS_DELIVERED',
  ERROR = 'ERROR',
  CLOSE = 'CLOSE',
}

export enum DunsguideRegisteredStatusEnum {
  Pending = 'Pending',
  Delivered = 'Delivered',
  NA = 'NA',
  Canceled = 'Canceled',
}

// Not a REAL status
export enum DunsNumberStatusEnum {
  Pending = 'Pending',
  Processing = 'Processing',
  Delivered = 'Delivered',
}

export enum CredibilityCertificateStatusEnum {
  Pending = 'Pending',
  Delivered = 'Delivered',
  NA = 'NA',
  Canceled = 'Canceled',
}

export enum ESGStatusEnum {
  NA = 'NA',
  Empty = '',
  Pending = 'Pending',
  FormSent = 'Form Sent',
  InfoUpdateRequest = 'Info update request',
  UnderReview = 'Under review',
  ValidatedForm = 'Validated form',
  Delivered = 'Delivered',
  Canceled = 'Canceled',
}

export interface DunsguideRegisteredExtraInfo {}

export interface DunsNumberExtraInfo {
  qrCode: any;
  dunsAssignedDate: string;
  dunsLink: string;
}

export interface CredibilityCertificateExtraInfo {
  issuedDate: string;
  countryManagerName: string;
  countryManagerSign: string;
}

export interface ESGExtraInfo {
  dueDate: string;
  profileLink: string;
}

export interface ProductStatus {
  order: number;
  product: ProductsEnum;
  status: ProductStatusEnum;
  extraInfo: DunsguideRegisteredExtraInfo | DunsNumberExtraInfo | CredibilityCertificateExtraInfo | ESGExtraInfo;
}

export class HomeResponseDto {
  duns: string | null;
  decryptedDuns: string | null;
  logo: string | null;
  plan: ProductEnum | null;
  expirationDate: string | null;
  productStatus: ProductStatus[];
  upcomingPlan: string | null;
  pendingPlan: string | null;
  upcomingPlanAvailableDate: string | null;
}

export class AdminCompanyDto {
  static getCountryCode(company: AdminCompanyDto): string {
    // defaults to unknown country code
    return company?.countryCode?.trim()?.toUpperCase() || 'ZZ';
  }

  aclCompanyId: string;
  primaryName: string;
  secondaryName: string;
  logo: string;
  slug: string;
  countryCode: string;
  taxId?: string;
  duns?: string;
  createdAt: string;
  status: CompanyStatusEnum;

  claimStatus: ClaimRequestStatus;

  bundleStatus: BundleProcessStatus;

  homeStatus: HomeResponseDto;
  branches: BranchAddonDto[];
  officeType?: OfficeType;
  shortAddress?: string;
  fullAddress?: string;
  workflows: Workflow[];
  hasDunsRequestedPreviously: boolean;
  requiresAttention: boolean;
  settings?: CompanySettingsDTO;
}

export class CompanySettingsDTO {
  pulsoAiEnabled: boolean;
}

// Company
export enum OfficeType {
  HEADQUARTERS = 'headquarters',
  BRANCH = 'branch',
  FOREIGN_BRANCH = 'foreign_branch',
  SINGLE = 'single',
}

export interface Company extends AutobahnCompany, DunsguideCompany {
  officeType: OfficeType;
  industries: Sic[];
  industrySet: IndustrySetResponseDto | null;
  branches: BranchAddonDto[];
  isClaimed: boolean;
}

export enum NumberType {
  PHONE = 'phone',
  FAX = 'fax',
}

export enum DayCode {
  SUNDAY = 'sun',
  MONDAY = 'mon',
  TUESDAY = 'tues',
  WEDNESDAY = 'wed',
  THURSDAY = 'thur',
  FRIDAY = 'fri',
  SATURDAY = 'sat',
  BUSINESS_DAYS = 'business_days',
  EVERY_DAY = 'every_day',
}

export enum EmailType {
  PRIMARY = 'primary',
  CONNECT = 'connect',
}

export class BranchAddonDto {
  duns: string;
  legalName: string;
  address: string;
}

export interface ICompanySuggestionWithLogoAndEncryptedDuns {
  name: string;
  legalName: string;
  tradeName: string;
  taxId: string;
  id: string;
  slug: string;
  dunsSuffix: string;
  officeType?: OfficeType;
  location: string;
  shortLocation: string;
  logo: string | null;
  encryptedDuns: string;
  hasDunsRequestedPreviously: boolean;
  countryCode?: string;
}
